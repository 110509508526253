import { GeoloniaMap } from '@geolonia/embed-react';
import type { Map } from '@geolonia/embed';
import React, { useRef, useState, useCallback, FormEvent, MutableRefObject, useEffect, useLayoutEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './TestTool.scss';
import {
  ApiKey,
  EstateIdResp,
  GetEstateIdByAddressParams,
  GetEstateIdParams,
  useGetApiKeysQuery,
  useGetEstateIdByAddressQuery,
  useGetEstateIdQuery,
  useSubmitFeedbackRequestMutation,
} from '../redux/mainApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import LoadingSpinner from '../components/LoadingSpinner';
import { ToastContext } from '../contexts/toast';
import type maplibregl from 'maplibre-gl';

const NORM_FAILED_MAP: { [key: string]: string } = {
  'neighborhood_not_recognized': '町丁目が認識できませんでした。',
  'city_not_recognized': '市区町村が認識できませんでした。',
  'prefecture_not_recognized': '都道府県が認識できませんでした。',
  'geo_oaza': '番地・号が認識できませんでした。',
  'geo_koaza': '番地・号が認識できませんでした。',
  'geo_banchi': '号が認識できませんでした。',
};


const removePaidResponse = (data: EstateIdResp) => {
  return data.map((estateId) => {
    const trimmed = { ...estateId, address: undefined, query: { ...estateId.query, address: undefined } };
    delete trimmed.address;
    delete trimmed.query.address;
    return trimmed;
  });
};

const _formatError = (input: FetchBaseQueryError | SerializedError | undefined): { errorMessage?: string, errorCodeDetail?: string, address?: string } => {
  if (typeof input === 'undefined') return {};

  const except = '予期せぬ例外が起こりました。時間を開けてもう一度試してください。';
  let errorMessage = except;
  let errorCodeDetail: string | undefined;
  let address: string | undefined;

  if (!('data' in input) || !input.data) {
    errorMessage = except;
  } else {
    const data = input.data as any;
    errorCodeDetail = data.error_code_detail;
    address = data.address;
    if (data.error_code === 'normalization_failed') {
      errorMessage = NORM_FAILED_MAP[data.error_code_detail] || except;
    } else if (data.error_code === 'address_not_verified') {
      errorMessage = '住所が見つかりませんでした。';
    } else if (data.message === 'Missing querystring parameter `q`.') {
      errorMessage = '住所を入力してください。';
    } else if (data.error_description === 'not_found') {
      errorMessage = '見つかりませんでした。';
    }
  }
  return { errorMessage, errorCodeDetail, address };
};

type IndividualTestToolProps = {
  apiKey: ApiKey
}

type ResultAreaProps = {
  result?: string
}
const ResultArea: React.FC<ResultAreaProps> = ({ result }) => {
  if (!result) return null;

  return <div className="p-2">
    <pre className="result-area bg-light border rounded-3">
      {result}
    </pre>
  </div>;
};

type EstateIdFeedbackModalState = {
  feedbackType: 'idMerge' | 'idSplit' | 'locationFix' | 'nameChange' // 'addBanchiGo' is separated
  submitFlag?: boolean
}

const EstateIdFeedbackModalInitialState: EstateIdFeedbackModalState = {
  feedbackType: 'idMerge',
};

interface EstateIdFeedbackFormControls extends HTMLFormControlsCollection {
  feedbackType: RadioNodeList
  idMergeList: HTMLTextAreaElement
  idMergeConfirm: HTMLTextAreaElement
  idSplitLatLng: HTMLInputElement
  idSplitBuilding: HTMLInputElement
  idSplitConfirm: HTMLTextAreaElement
  locationFixLatLng: HTMLInputElement
  locationFixConfirm: HTMLTextAreaElement
  nameChangeContents: HTMLTextAreaElement
  nameChangeConfirm: HTMLTextAreaElement
  addBanchiGoContents: HTMLTextAreaElement
  addBanchiGoConfirm: HTMLInputElement
}

const EstateIdFeedbackModal: React.FC<{data: EstateIdResp, editingId?: string, onClose: () => void, apiKey: string}> = (props) => {
  const { data, editingId, onClose, apiKey } = props;
  const [ state, setState ] = useState<EstateIdFeedbackModalState>(EstateIdFeedbackModalInitialState);
  const [ submitFeedback, { isLoading, isError } ] = useSubmitFeedbackRequestMutation();
  const modalOpen = typeof editingId !== 'undefined';
  const currentId = typeof editingId !== 'undefined' && data.find(({ID}) => ID === editingId);
  const formRef = useRef<HTMLFormElement>(null);
  const { show } = useContext(ToastContext);
  const [selectedLngLat, setSelectedLngLat] = useState<maplibregl.LngLat | null>(null);

  const geoloniaMapOnLoad = useCallback((map: Map) => {
    map
      .on('click', (e) => map.flyTo({ center: e.lngLat, duration: 300 }))
      .on('moveend', (e) => setSelectedLngLat(e.target.getCenter()))
      .on('remove', () => setSelectedLngLat(null));
  }, []);

  useEffect(() => {
    setState(EstateIdFeedbackModalInitialState);
    formRef.current?.reset();
  }, [editingId]);

  useLayoutEffect(() => {
    if (!modalOpen) return;

    const div = document.createElement('div');
    div.className = 'modal-backdrop fade';
    document.body.appendChild(div);
    div.classList.add('show');

    return () => {
      document.body.removeChild(div);
    };
  }, [modalOpen]);

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault();
    if (!editingId || !currentId) return;

    const elements = event.currentTarget.elements as EstateIdFeedbackFormControls;

    setState((s) => ({...s, submitFlag: true}));
    submitFeedback({
      id: editingId,
      feedbackType: state.feedbackType,
      apiKey: apiKey,
      currentAddress: `${currentId.address.ja.prefecture}${currentId.address.ja.city}${currentId.address.ja.address1}${currentId.address.ja.address2}\n${currentId.address.ja.other}`,
      idMerge: state.feedbackType === 'idMerge' ? {
        list: elements.idMergeList.value,
        confirm: elements.idMergeConfirm.value,
      } : undefined,
      idSplit: state.feedbackType === 'idSplit' ? {
        latLng: elements.idSplitLatLng.value,
        building: elements.idSplitBuilding.value,
        confirm: elements.idSplitConfirm.value,
      } : undefined,
      locationFix: state.feedbackType === 'locationFix' ? {
        latLng: elements.locationFixLatLng.value,
        confirm: elements.locationFixConfirm.value,
      } : undefined,
      nameChange: state.feedbackType === 'nameChange' ? {
        contents: elements.nameChangeContents.value,
        confirm: elements.nameChangeConfirm.value,
      } : undefined,
    });
  }, [apiKey, currentId, editingId, state.feedbackType, submitFeedback]);

  useEffect(() => {
    if (state.submitFlag && !isLoading) {
      const message = isError ? '予期せぬ例外が起こりました。時間を開けてもう一度試してください。' : 'ご依頼を受け付けました。';
      const type = isError ? 'failure' : 'success';
      show('修正依頼・データ報告', message, type, 10_000);
      onClose();
    }
  }, [isError, isLoading, onClose, show, state.submitFlag]);

  const lat = parseFloat((currentId && currentId.location?.lat) || '');
  const lng = parseFloat((currentId && currentId.location?.lng) || '');
  const latStr = Number.isNaN(lat) ? '0' : lat.toString();
  const lngStr = Number.isNaN(lng) ? '0' : lng.toString();

  return <div className={modalOpen ? 'modal fade show d-block' : 'modal fade'}>
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">修正依頼・データ報告</h5>
          <button type="button" className="btn-close" aria-label="閉じる" onClick={onClose} disabled={isLoading}></button>
        </div>
        <div className="modal-body">
          <p>
            この窓口で各種依頼を受け付けています。
          </p>
          {isLoading && <LoadingSpinner />}
          <form id="feedbackForm" className={`${isLoading && 'd-none'}`} onSubmit={handleSubmit} ref={formRef}>
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="feedbackType"
                  id="feedbackTypeIdMerge"
                  value="idMerge"
                  onChange={() => { setState((s) => ({...s, feedbackType: 'idMerge'})); }}
                  checked={state.feedbackType === 'idMerge'}
                />
                <label className="form-check-label" htmlFor="feedbackTypeIdMerge">同じ物件に異なるIDが設定されている</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="feedbackType"
                  id="feedbackTypeIdSplit"
                  value="idSplit"
                  onChange={() => { setState((s) => ({...s, feedbackType: 'idSplit'})); }}
                  checked={state.feedbackType === 'idSplit'}
                />
                <label className="form-check-label" htmlFor="feedbackTypeIdSplit">異なる物件に同じIDが設定されている</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="feedbackType"
                  id="feedbackTypeLocationFix"
                  value="locationFix"
                  onChange={() => { setState((s) => ({...s, feedbackType: 'locationFix'})); }}
                  checked={state.feedbackType === 'locationFix'}
                />
                <label className="form-check-label" htmlFor="feedbackTypeLocationFix">物件の緯度経度が間違っている</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="feedbackType"
                  id="feedbackTypeNameChange"
                  value="nameChange"
                  onChange={() => { setState((s) => ({...s, feedbackType: 'nameChange'})); }}
                  checked={state.feedbackType === 'nameChange'}
                />
                <label className="form-check-label" htmlFor="feedbackTypeNameChange">地名・住所・ビル名が間違っている</label>
              </div>
            </div>
            <hr />
            { state.feedbackType === 'idMerge' && <fieldset>
              <div className="mb-3">
                <label htmlFor="idMergeIdList">統合が必要なIDのリスト</label>
                <textarea
                  className="form-control"
                  id="idMergeList"
                  name="idMergeList"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  選択いただきました <code>{editingId}</code> はリストに含む必要がありません。<br />
                  複数IDを統合したい場合は改行で入力してください。
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="idMergeConfirm">同じ物件であることの確認方法</label>
                <textarea
                  className="form-control"
                  id="idMergeConfirm"
                  name="idMergeConfirm"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  物件の確認ができるリンクなどを入力してください。
                </div>
              </div>
            </fieldset> }

            { state.feedbackType === 'idSplit' && <fieldset>
              <div className="mb-3">
                <label>修正対象の不動産オープン ID</label>
                <div><code>{editingId}</code></div>
              </div>
              <div className="mb-3">
                <label htmlFor="locationFixLatLng">異なる物件の緯度と経度</label>
                <div className="mb-2 map-wrapper">
                  <GeoloniaMap
                    mapStyle="geolonia/gsi"
                    onLoad={geoloniaMapOnLoad}
                    marker="on"
                    lat={latStr}
                    lng={lngStr}
                    zoom="17"
                    className="gmap mb-2"
                  ></GeoloniaMap>
                  <div className="center-cross"></div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="idSplitLatLng"
                  name="idSplitLatLng"
                  placeholder="未選択"
                  required={true}
                  value={selectedLngLat ? `${selectedLngLat.lat},${selectedLngLat.lng}` : undefined}
                />
                <div className="form-text">
                  他の物件の場所を地図上でクリックしてください。
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="idSplitBuilding">物件名を入力してください</label>
                <input
                  type="text"
                  className="form-control"
                  id="idSplitBuilding"
                  name="idSplitBuilding"
                  required={true}
                  placeholder={'○○マンションB棟'}
                />
                <div className="form-text">
                  物件の確認ができるリンクなどを入力してください。
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="idSplitConfirm">別の物件であることの確認方法</label>
                <textarea
                  className="form-control"
                  id="idSplitConfirm"
                  name="idSplitConfirm"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  物件の確認ができるリンクなどを入力してください。
                </div>
              </div>
            </fieldset> }

            { state.feedbackType === 'locationFix' && <fieldset>
              <div className="mb-3">
                <label>修正対象の不動産オープン ID</label>
                <div><code>{editingId}</code></div>
              </div>
              <div className="mb-3">
                <label htmlFor="locationFixLatLng">修正後の緯度と経度</label>
                <div className="mb-2 map-wrapper">
                  <GeoloniaMap
                    mapStyle="geolonia/gsi"
                    onLoad={geoloniaMapOnLoad}
                    marker="off"
                    lat={latStr}
                    lng={lngStr}
                    zoom="17"
                    className="gmap mb-2"
                  ></GeoloniaMap>
                  <div className="center-cross"></div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="locationFixLatLng"
                  name="locationFixLatLng"
                  placeholder="未選択"
                  required={true}
                  value={selectedLngLat ? `${selectedLngLat.lat},${selectedLngLat.lng}` : undefined}
                />
                <div className="form-text">
                  修正後の場所を地図上でクリックしてください。
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="locationFixConfirm">建物の場所の確認方法</label>
                <textarea
                  className="form-control"
                  id="locationFixConfirm"
                  name="locationFixConfirm"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  物件の確認ができるリンクなどを入力してください。
                </div>
              </div>
            </fieldset>}

            { state.feedbackType === 'nameChange' && <fieldset>
              <div className="mb-3">
                <label>修正対象の不動産オープン ID</label>
                <div><code>{editingId}</code></div>
              </div>
              <div className="mb-3">
                <label htmlFor="nameChangeContents">変更内容</label>
                <textarea
                  className="form-control"
                  id="nameChangeContents"
                  name="nameChangeContents"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  具体的に入力してください。
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="nameChangeConfirm">地名変更、住所変更の確認方法</label>
                <textarea
                  className="form-control"
                  id="nameChangeConfirm"
                  name="nameChangeConfirm"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  地名や住所が変更になったことを確認できるページへのリンクなどがありましたら入力してください。
                </div>
              </div>
            </fieldset>}
          </form>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={onClose} disabled={isLoading}>キャンセル</button>
          <button type="submit" className="btn btn-success" form="feedbackForm" disabled={isLoading}>送信</button>
        </div>
      </div>
    </div>
  </div>;
};

const EstateIdBanchiGoAdditionalFeedbackModal: React.FC<{address?: string, onClose: () => void}> = (props) => {
  const { address, onClose } = props;
  const [ state, setState ] = useState<EstateIdFeedbackModalState>(EstateIdFeedbackModalInitialState);
  const [editingAddress, setEditingAddress] = useState(address);
  const [ submitFeedback, { isLoading, isError } ] = useSubmitFeedbackRequestMutation();
  const modalOpen = typeof address !== 'undefined';
  const formRef = useRef<HTMLFormElement>(null);
  const { show } = useContext(ToastContext);

  useEffect(() => {
    setState(EstateIdFeedbackModalInitialState);
    formRef.current?.reset();
  }, []);

  useLayoutEffect(() => {
    if (!modalOpen) return;

    const div = document.createElement('div');
    div.className = 'modal-backdrop fade';
    document.body.appendChild(div);
    div.classList.add('show');

    return () => {
      document.body.removeChild(div);
    };
  }, [modalOpen]);

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault();
    if (!address) return;

    const elements = event.currentTarget.elements as EstateIdFeedbackFormControls;
    setState((s) => ({...s, submitFlag: true}));
    submitFeedback({
      feedbackType: 'addBanchiGo',
      currentAddress: address,
      addBanchiGo: {
        contents: elements.addBanchiGoContents.value,
        confirm: elements.addBanchiGoConfirm.value,
      },
    });
  }, [address, submitFeedback]);

  useEffect(() => {
    if (state.submitFlag && !isLoading) {
      const message = isError ? '予期せぬ例外が起こりました。時間を開けてもう一度試してください。' : 'ご依頼を受け付けました。';
      const type = isError ? 'failure' : 'success';
      show('番地または号の追加依頼', message, type, 10_000);
      onClose();
    }
  }, [state.submitFlag, isLoading, onClose, show, isError]);

  const handleAddBanchiGoContentsChange = useCallback<React.FormEventHandler<HTMLInputElement>>((event) => {
    setEditingAddress(event.currentTarget.value);
  }, []);

  return <div className={modalOpen ? 'modal fade show d-block' : 'modal fade'}>
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">番地または号の追加依頼</h5>
          <button type="button" className="btn-close" aria-label="閉じる" onClick={onClose} disabled={isLoading}></button>
        </div>
        <div className="modal-body">
          <p>
            この窓口で番地または号の追加依頼を受け付けています。
          </p>
          {isLoading && <LoadingSpinner />}
          <form id="feedbackForm" className={`${isLoading && 'd-none'}`} onSubmit={handleSubmit} ref={formRef}>
            <fieldset>
              <div className="mb-3">
                <label htmlFor="addBanchiGoContents">新しい番地または号を追加する住所</label>
                <input
                  type="text"
                  className="form-control"
                  id="addBanchiGoContents"
                  name="addBanchiGoContents"
                  required={true}
                  value={editingAddress}
                  onChange={handleAddBanchiGoContentsChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="addBanchiGoConfirm">番地または号の追加の確認方法</label>
                <textarea
                  className="form-control"
                  id="addBanchiGoConfirm"
                  name="addBanchiGoConfirm"
                  required={true}
                  rows={3}
                />
                <div className="form-text">
                  番地または号が追加されたことを確認できるページへのリンクなどがありましたら入力してください。
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={onClose} disabled={isLoading}>キャンセル</button>
          <button type="submit" className="btn btn-success" form="feedbackForm" disabled={isLoading}>送信</button>
        </div>
      </div>
    </div>
  </div>;
};

const EstateIdPreviewTool: React.FC<{apiKey: ApiKey, data: EstateIdResp}> = ({apiKey, data}) => {
  const [editingId, setEditingId] = useState<string | undefined>();

  return (<>
    <table className="table">
      <thead>
        <tr>
          <th>不動産オープン ID</th>
          <th>住所</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((estateId) => (
          <tr key={estateId.ID}>
            <td><code>{estateId.ID}</code></td>
            <td>
              <div>
                {estateId.address.ja.prefecture}
                {estateId.address.ja.city}
                {estateId.address.ja.address1}
                {estateId.address.ja.address2}
              </div>
              { estateId.address.ja.other &&
                <div>
                  {estateId.address.ja.other}
                </div>
              }
            </td>
            <td>
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => setEditingId(estateId.ID)}
              >
                修正依頼
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <EstateIdFeedbackModal
      data={data}
      editingId={editingId}
      onClose={() => setEditingId(undefined)}
      apiKey={apiKey.apiKey}
    />
  </>);
};

const BanchiGoAdditionalTool: React.FC<{ address: string }> = (props) => {
  const { address } = props;
  const [open, setModalOpen] = useState(false);
  return <>
    <button
      type="button"
      className="btn btn-success btn-sm"
      onClick={() => setModalOpen(true)}
    >
        番地または号の追加依頼
    </button>
    {open && <EstateIdBanchiGoAdditionalFeedbackModal
      address={address}
      onClose={() => setModalOpen(false)}
    />}
  </>;
};

type QueryInnerProps<T = any> = {apiKey: ApiKey, query: T};

const EstateIdByAddressInner: React.FC<QueryInnerProps<GetEstateIdByAddressParams>> = ({query, apiKey}) => {
  const { data, error, isFetching } = useGetEstateIdByAddressQuery(query);
  const mapRef = useRef<Map>(null) as MutableRefObject<Map>;
  const { errorMessage, errorCodeDetail, address } = _formatError(error);
  const [selectedTab, setSelectedTab] = useState<'ids' | 'rawresp'>('ids');

  if (errorMessage) {
    return <>
      <div className="alert alert-danger">
        {errorMessage}
      </div>
      {
        (errorCodeDetail === 'geo_koaza' || errorCodeDetail === 'geo_banchi') &&
        address &&
          <BanchiGoAdditionalTool address={address} />
      }
    </>;
  }

  if (isFetching) {
    return <div>
      <LoadingSpinner />
    </div>;
  }

  const firstId = data && data[0];

  return <>
    { firstId && apiKey.plan !== 'free' &&
      <GeoloniaMap
        mapStyle="geolonia/gsi"
        mapRef={mapRef}
        marker="on"
        openPopup="on"
        lat={`${firstId.location?.lat || 0}`}
        lng={`${firstId.location?.lng || 0}`}
        zoom="17"
        className="gmap mb-2"
      >
        <span>
          {firstId.address.ja.prefecture}
          {firstId.address.ja.city}
          {firstId.address.ja.address1}
          {firstId.address.ja.address2}
          {firstId.status === 'addressPending' && <em className="address-pending">（確認中の住所）</em>}
        </span><br />
        <code>{firstId.ID}</code>
      </GeoloniaMap>
    }

    <ul className="nav nav-tabs my-4">
      <li className="nav-item">
        <button
          type="button"
          onClick={() => setSelectedTab('ids')}
          className={`nav-link ${selectedTab === 'ids' ? 'active' : ''}`}
        >
          一覧
        </button>
      </li>
      <li className="nav-item">
        <button
          type="button"
          onClick={() => setSelectedTab('rawresp')}
          className={`nav-link ${selectedTab === 'rawresp' ? 'active' : ''}`}
        >
          API のレスポンス
        </button>
      </li>
    </ul>

    { selectedTab === 'ids' &&
      data && <EstateIdPreviewTool apiKey={apiKey} data={data} />
    }
    { selectedTab === 'rawresp' &&
      <ResultArea result={data ? JSON.stringify(apiKey.plan === 'free' ? removePaidResponse(data) : data, undefined, 2) : undefined} />
    }
  </>;
};

const EstateIdByAddressTestTool: React.FC<IndividualTestToolProps> = ({apiKey}) => {
  const [ query, setQuery ] = useState<GetEstateIdByAddressParams | undefined>();

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setQuery({
      apiKey: apiKey.apiKey,
      address: event.currentTarget['address'].value,
      ignoreBuilding: event.currentTarget['ignore-building'].checked,
    });
  }, [apiKey.apiKey]);

  return <>
    <form onSubmit={handleSubmit} className="mb-3">
      <div className="form-group p-2">
        <label htmlFor="address">住所を入力してください。</label>
        <input type="text" id="address" className="form-control" name="address" placeholder="例: 東京都文京区千石4丁目15-7"/>
      </div>
      <div className="form-check p-2 mx-4">
        <input type="checkbox" id="ignore-building" className="form-check-input" name="ignore-building" />
        <label htmlFor="ignore-building" className="form-check-label">建物名を無視する</label>
      </div>
      <button type="submit" className="btn btn-primary p-2">不動産オープン IDを取得</button>
    </form>

    { query && <EstateIdByAddressInner apiKey={apiKey} query={query} /> }
  </>;
};

const EstateIdQueryInner: React.FC<QueryInnerProps<GetEstateIdParams>> = ({query, apiKey}) => {
  const { data, error, isFetching } = useGetEstateIdQuery(query);
  const { errorMessage } = _formatError(error);

  if (errorMessage) {
    return (
      <div className="alert alert-danger">
        {errorMessage}
      </div>
    );
  }

  if (isFetching) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  return <ResultArea result={data ? JSON.stringify(data, undefined, 2) : undefined} />;
};

const EstateIdQueryTestTool: React.FC<IndividualTestToolProps> = ({apiKey}) => {
  const [ query, setQuery ] = useState<GetEstateIdParams | undefined>();

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setQuery({
      apiKey: apiKey.apiKey,
      id: event.currentTarget['idLookup'].value,
    });
  }, [apiKey.apiKey]);

  return <>
    <h2 className="h3">不動産オープン ID 参照</h2>
    <form onSubmit={handleSubmit} className="mb-3">
      <div className="form-group p-2">
        <label htmlFor="idLookup">IDを入力してください。</label>
        <input type="text" className="form-control" id="idLookup" name="idLookup"/>
      </div>
      <button type="submit" className="btn btn-primary p-2">参照する</button>
    </form>

    { query && <EstateIdQueryInner apiKey={apiKey} query={query} /> }
  </>;
};

const TestTool: React.FC = () => {
  const { apiKey, isLoading } = useGetApiKeysQuery(undefined, {
    selectFromResult: ({data, isLoading}) => ({
      apiKey: data?.keys && data.keys[data.keys.length - 1],
      isLoading,
    }),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (typeof apiKey === 'undefined') {
    return <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1>テストツール</h1>
      </div>
      <p>
        テストツールをご利用になる前に API キーの発行が必要です。<Link to="/api-keys">API キー</Link>のページで発行してください。
      </p>
    </>;
  }

  return <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1>テストツール</h1>
    </div>

    <EstateIdByAddressTestTool apiKey={apiKey} />

    <hr/>

    <EstateIdQueryTestTool apiKey={apiKey} />
  </>;
};

export default TestTool;
