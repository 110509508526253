import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { setApiToken } from '../redux/authSlice';

const useAuth0Connector = () => {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(setApiToken(token));
      } catch (e: any) {
        const error = e as OAuthError;
        if (error.error === 'consent_required') {
          const token = await getAccessTokenWithPopup();
          dispatch(setApiToken(token));
        } else if (error.error === 'login_required') {
          await loginWithRedirect();
        } else {
          throw e;
        }
      }
    })();
  }, [dispatch, getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect]);
};

export default useAuth0Connector;
