import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { Auth0Provider } from '@auth0/auth0-react';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';

const stageDetection = process.env.REACT_APP_API_BASE_URL?.match(/(v[0-9][1-9]*)|dev$/);
window.PROP_ID_API_STAGE = stageDetection ? stageDetection[0] : null;

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={window.location.origin + window.location.pathname}
        audience="https://api.propid.jp"
        scope="access"
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
