import { Link } from 'react-router-dom';

const Dashboard: React.FC = () => {
  return <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1>不動産オープン ID - 管理画面 へようこそ</h1>
    </div>
    <h2>不動産オープン ID とは</h2>
    <p>
      不動産オープン ID API とは、入力された住所情報に対して一意の ID を返すアプリケーション・プログラム・インターフェースです。
      <br />住所の表記揺れや架空住所への対策といった問題を解決するために開発、運用しています。
    </p>
    <p>
      API を利用するためには、この管理画面で発行・管理できるAPI キーとアクセストークンが必要です。
      <br />「<Link to="/api-keys">API キー</Link>」から発行が可能です。
    </p>
    <h2>API 仕様書</h2>
    <p>
      仕様書は、エンドポイント、認証方法、パラメータ、レスポンスの例など、開発者が API を利用するために必要な情報をまとめたものです。開発者に渡してください。
    </p>
    <h2>利用プランと料金</h2>
    <p>不動産オープン ID API は、以下の料金プランで提供します。</p>
    <p>プランの変更をご希望の方は、お手数ですが<a target="_blank" rel="noopener noreferrer" href="https://propid.slack.com/">専用のSlack</a>までお問い合わせください。</p>
    <h3>無料プラン</h3>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>エンドポイント名</th>
          <th>入力</th>
          <th>出力</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>不動産オープン ID API</td>
          <td>住所文字列</td>
          <td>不動産オープン ID<br /><a href="https://geolonia.github.io/prop-id-api/api-specs.html#normalization_level" target="_blank" rel="noopener noreferrer">住所正規化レベル</a><br />正規化済み住所</td>
        </tr>
        <tr>
          <td>不動産オープン ID 参照 API</td>
          <td>不動産オープン ID</td>
          <td>不動産オープン ID（統合確認）</td>
        </tr>
      </tbody>
    </table>
    <p className="table-note">不動産オープン ID の取得は無料で利用できます。「参照 API」では、ID の修正や統合があった結果、ID が新しくなっている場合に正しい不動産オープン ID を返します。</p>

    <h3>有料プラン</h3>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>エンドポイント名</th>
          <th>入力</th>
          <th>出力</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>不動産オープン ID API</td>
          <td>住所文字列</td>
          <td>不動産オープン ID<br /><a href="https://geolonia.github.io/prop-id-api/api-specs.html#normalization_level" target="_blank" rel="noopener noreferrer">住所正規化レベル</a><br /><a href="https://geolonia.github.io/prop-id-api/api-specs.html#geocoding_level" target="_blank" rel="noopener noreferrer">ジオコーディングレベル</a><br />正規化済み住所<br />緯度経度</td>
        </tr>
        <tr>
          <td>不動産オープン ID 参照 API</td>
          <td>不動産オープン ID</td>
          <td>不動産オープン ID（統合確認）<br /><a href="https://geolonia.github.io/prop-id-api/api-specs.html#normalization_level" target="_blank" rel="noopener noreferrer">住所正規化レベル</a><br /><a href="https://geolonia.github.io/prop-id-api/api-specs.html#geocoding_level" target="_blank" rel="noopener noreferrer">ジオコーディングレベル</a><br />正規化済み住所<br />緯度経度</td>
        </tr>
      </tbody>
    </table>
    <p className="table-note">有料プランは、レスポンスに正規化済みの住所と緯度経度を含みます。不動産オープン ID と正規化済み住所は保存、再利用、二次利用等が可能です。ただし、緯度と経度は保存することができず、30日のキャッシュが可能です。詳細は利用規約を確認してください。</p>

    <h3>有料プランの料金</h3>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th>不動産テック協会会員</th>
          <td>月額 1 万円（税込）</td>
        </tr>
        <tr>
          <th>不動産テック協会非会員</th>
          <td>月額 5 万円（税込）</td>
        </tr>
      </tbody>
    </table>
    <h2>サポート</h2>
    <p>利用方法についてのサポートは、<a target="_blank" rel="noopener noreferrer" href="https://propid.slack.com/">専用の Slack</a> で行っています。
      <br />この Slack のご利用の前に、<a target="_blank" rel="noopener noreferrer" href="https://join.slack.com/t/propid/shared_invite/zt-p397ji8k-9MIqQyusRkmSEwsyatLc1Q">アカウントの作成</a>を行ってください。</p>
  </>;
};

export default Dashboard;
