import { useAuth0 } from '@auth0/auth0-react';
import {
  Switch,
  Link,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom';
import { HouseDoorFill, KeyFill, GearFill } from 'react-bootstrap-icons';

import TestTool from './pages/TestTool';
import ApiKeys from './pages/ApiKeys';
import Dashboard from './pages/Dashboard';
import { useEffect } from 'react';

const AuthenticatedApp: React.FC = () => {
  const history = useHistory();
  const {
    logout,
    user,
  } = useAuth0();

  useEffect(() => {
    const listenUnregister = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      listenUnregister();
    };
  }, [history]);

  return (
    <>
      <header className="navbar sticky-top flex-md-nowrap p-0 header">
        <Link to="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="不動産オープン ID" className="logo" />
        </Link>
        <span className="navbar-text px-3">
          {user?.email}
          {window.PROP_ID_API_STAGE === 'dev' && <em className="mx-2">{'[開発環境です]'}</em>}
        </span>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <button onClick={() => logout({returnTo: window.location.origin + window.location.pathname})} className="btn nav-link">
              ログアウト
            </button>
          </li>
        </ul>
      </header>
      <div className="container-fluid">
        <div className="row">
          <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link" activeClassName="active" exact={true}>
                    <HouseDoorFill className="feather" /> ダッシュボード
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/api-keys" className="nav-link" activeClassName="active" exact={true}>
                    <KeyFill className="feather" /> API キー
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/test-tool" className="nav-link" activeClassName="active" exact={true}>
                    <GearFill className="feather" /> テストツール
                  </NavLink>
                </li>
              </ul>
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center title-h6">
                <span>リンク</span>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <a className="nav-link" href="https://geolonia.github.io/prop-id-api/api-specs.html" target="_blank" rel="noopener noreferrer">API 仕様書</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://geolonia.github.io/prop-id-api/api-faq.html" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://docs.google.com/document/d/1pwNzvrmYwMBBjDuJBc8ND8CAFAMiFMSBmygkXZHVXPE/edit?usp=sharing" target="_blank" rel="noopener noreferrer">利用規約</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://propid.slack.com/" target="_blank" rel="noopener noreferrer">サポート</a>
                </li>
              </ul>
            </div>
            <div className="sidebar-logo-container">
              <img src={`${process.env.PUBLIC_URL}/company-logos.png`} alt="不動産テック協会、Geolonia" className="w-50"/>
            </div>
          </nav>
          <main className="h-100 col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <Switch>
              <Route exact path="/test-tool" component={TestTool} />
              <Route exact path="/api-keys" component={ApiKeys} />
              <Route exact path="/" component={Dashboard} />
            </Switch>
          </main>
        </div>
      </div>
      <footer className="mt-auto py-2 bg-dark admin-footer">
        <div className="container-fluid">
          <ul className="list-inline text-white-50 my-0 text-center small">
            <li className="list-inline-item">
              <a className="link-light" href="https://retechjapan.org/" target="_blank" rel="noopener noreferrer">不動産テック協会</a>
              &nbsp;
              Powered by <a className="link-light" href="https://geolonia.com/" target="_blank" rel="noopener noreferrer">Geolonia</a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default AuthenticatedApp;
