import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

interface BaseApiKey {
  apiKey: string
  plan: 'paid' | 'free'
}

export interface ApiKey extends BaseApiKey {
  apiKey: string
  createdAt: string
  description: string
  userId: string
}

export interface ApiKeyWithAccessToken extends BaseApiKey {
  accessToken: string
}

export type GetApiKeysResp = {
  error: false
  keys: ApiKey[]
}

export type CreateApiKeyResp = {
  error: false
  key: ApiKeyWithAccessToken
}

type AddressObj = {
  ja: {
    prefecture: string
    city: string
    address1: string
    address2: string
    other: string
  }
}

export type EstateIdResp = {
  ID: string
  normalization_level: string
  query: {
    input: string,
    address: AddressObj
  },
  address: AddressObj
  geocoding_level?: string
  location?: {
    lat: string
    lng: string
  }
  status: null | string
}[]

export type GetEstateIdByAddressParams = {
  apiKey: string
  address: string
  ignoreBuilding: boolean
}

export type GetEstateIdParams = {
  apiKey: string
  id: string
}

export type SubmitFeedbackRequestParams = {
  id: string
  feedbackType:  'idMerge' | 'idSplit' | 'locationFix' | 'nameChange',
  apiKey: string
  currentAddress: string
  idMerge?: {
    list: string
    confirm: string
  }
  idSplit?: {
    latLng: string
    building: string
    confirm: string
  }
  locationFix?: {
    latLng: string
    confirm: string
  }
  nameChange?: {
    contents: string
    confirm: string
  }
} | {
  feedbackType: 'addBanchiGo'
  currentAddress: string
  addBanchiGo: {
    contents: string
    confirm: string
  }
}

export const mainApi = createApi({
  reducerPath: 'mainApi',
  tagTypes: ['ApiKey', 'EstateId'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL!,
    prepareHeaders: async (headers, { getState }) => {
      const state = getState() as RootState;
      if (state.auth.apiToken) {
        headers.set('authorization', `Bearer ${state.auth.apiToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getApiKeys: builder.query<GetApiKeysResp, void>({
      query: () => '/admin/keys',
      providesTags: (result) => (
        result ?
          [
            ...result.keys.map(({apiKey}) => ({ type: 'ApiKey', id: apiKey } as const)),
            { type: 'ApiKey', id: 'LIST' },
          ] : [
            { type: 'ApiKey', id: 'LIST' },
          ]
      ),
    }),
    createApiKey: builder.mutation<CreateApiKeyResp, void>({
      query: () => ({
        url: '/admin/keys',
        method: 'POST',
      }),
      invalidatesTags: [
        { type: 'ApiKey', id: 'LIST' },
      ],
    }),
    reissueApiKey: builder.mutation<CreateApiKeyResp, string>({
      query: (apiKey) => ({
        url: `/admin/keys/${apiKey}/reissue`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, apiKey) => ([
        { type: 'ApiKey', id: apiKey },
      ]),
    }),
    getEstateIdByAddress: builder.query<EstateIdResp, GetEstateIdByAddressParams>({
      query: (arg) => ({
        url: '/admin/query',
        params: {
          'api-key': arg.apiKey,
          q: arg.address,
          'ignore-building': arg.ignoreBuilding,
        },
      }),
      providesTags: () => [{ type: 'EstateId' }],
    }),
    getEstateId: builder.query<EstateIdResp, GetEstateIdParams>({
      query: (arg) => ({
        url: `/admin/query/${arg.id}`,
        params: {
          'api-key': arg.apiKey,
        },
      }),
      providesTags: [],
      // (_result, _error, {id}) => ([
      //   { type: 'EstateId', id },
      // ]),
    }),
    submitFeedbackRequest: builder.mutation<void, SubmitFeedbackRequestParams>({
      query: (arg) => {
        if (arg.feedbackType === 'idSplit') {
          const [lat, lng] = arg.idSplit!.latLng.split(',');
          const building = arg.idSplit!.building;
          return {
            url: `/admin/query/${arg.id}/split`,
            params: {
              'api-key': arg.apiKey,
              lat: lat,
              lng: lng,
              building: building,
            },
            method: 'POST',
            body: {
              feedback: arg,
            },
          };
        } else {
          return {
            url: '/admin/feedback',
            method: 'POST',
            body: {
              feedback: arg,
            },
          };
        }
      },
      invalidatesTags: ['EstateId'],
    }),
  }),
});

export const {
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useReissueApiKeyMutation,

  useGetEstateIdByAddressQuery,
  useGetEstateIdQuery,

  useSubmitFeedbackRequestMutation,
} = mainApi;
