import React, { useCallback, Fragment } from 'react';
import { ApiKey, ApiKeyWithAccessToken, useCreateApiKeyMutation, useGetApiKeysQuery, useReissueApiKeyMutation } from '../redux/mainApi';
import './ApiKeys.scss';

type PlanLabelProps = {
  apiKey: ApiKey
}

export const PlanLabel: React.FC<PlanLabelProps> = ({apiKey}) => {
  return (
    <>
      <p>
        { (apiKey.plan === 'paid') ?
          (<span className="plan-label bg-primary text-white">有料プラン</span>) : (<span className="plan-label bg-dark text-white">無料プラン</span>)
        }
      </p>
    </>
  );
};

const generateIdIssueCodeSample = (apiKey: string = '<APIキー>') => {
  return `curl -v -G \\
  -H "x-access-token: <アクセストークン>" \\
  --data-urlencode "q=東京都千代田区永田町１丁目７−１" \\
  --data-urlencode "api-key=${apiKey}" \\
  "${process.env.REACT_APP_API_BASE_URL}/"
`.split('\n').filter((line) => !!line);
};

const generateIdQueryCodeSample = (apiKey: string = '<APIキー>') => {
  return `curl -v -G \\
  -H "x-access-token: <アクセストークン>" \\
  --data-urlencode "api-key=${apiKey}" \\
  "${process.env.REACT_APP_API_BASE_URL}/<不動産オープン ID>"
`.split('\n').filter((line) => !!line);
};

const CodeSample: React.FC<{code: string[]}> = ({code}) => {
  return <div className="sample-code">
    <pre>{code.map((line, index) => (<Fragment key={index}>
      {line}<br />
    </Fragment>))}</pre>
  </div>;
};

type CredentialsProps = {
  apiKey?: ApiKey
}

export const Credentials: React.FC<CredentialsProps> = (props) => {
  const [ createApiKey, {
    data: createApiKeyResp,
    isLoading: createApiKeyRespIsLoading,
  } ] = useCreateApiKeyMutation();
  const [ reissueApiKey, {
    data: reissueApiKeyResp,
    isLoading: reissueApiKeyRespIsLoading,
  } ] = useReissueApiKeyMutation();

  const issueKey = useCallback(() => {
    createApiKey();
  }, [createApiKey]);

  const apiKey = (
    reissueApiKeyResp?.key || createApiKeyResp?.key || props.apiKey
  ) as ApiKey | ApiKeyWithAccessToken | undefined;

  const reissueKey = useCallback(() => {
    if (!apiKey) return;
    reissueApiKey(apiKey.apiKey);
  }, [apiKey, reissueApiKey]);

  return (
    <>
      <dl>
        { apiKey && <>
          <dt>API キー</dt>
          <dd><code>{apiKey.apiKey}</code></dd>
          <dt>アクセストークン</dt>
          <dd>
            { 'accessToken' in apiKey ? (
              <>
                <div>
                  <code>
                    {apiKey.accessToken}
                  </code>
                </div>
                <small>このアクセストークンはセキュリティーのため、暗号化された状態で保存されます。このページから離れますと確認できなくなりますので、大事に保管してください。</small>
              </>
            ) : (
              <>
                セキュリティーのため、アクセストークンは発行時のみ表示されます。紛失の場合は再発行が必要となります。
              </>
            ) }
          </dd>
        </> }
        <dd>
          {!apiKey &&
            <div className="my-2">
              <button
                type="button"
                onClick={issueKey}
                className="btn btn-primary"
                disabled={createApiKeyRespIsLoading}
              >
                API キー発行
              </button>
            </div>
          }
          {apiKey &&
            <div className="my-2">
              <button
                type="button"
                onClick={reissueKey}
                className="btn btn-primary"
                disabled={reissueApiKeyRespIsLoading}
              >
                再発行
              </button>
            </div>
          }
        </dd>
      </dl>
    </>
  );
};

const ApiKeys: React.FC = () => {
  const { apiKey, isLoading } = useGetApiKeysQuery(undefined, {
    selectFromResult: ({data, isLoading}) => ({
      apiKey: data?.keys && data.keys[data.keys.length - 1],
      isLoading,
    }),
  });

  return <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1>APIキー</h1>
    </div>

    <h2>プラン</h2>
    { apiKey && <PlanLabel apiKey={apiKey} /> }
    <p>プラン変更につきましては、サポートまでお問い合わせ下さい。</p>
    {
      !isLoading && <>
        <h2>認証情報</h2>
        <Credentials apiKey={apiKey} />
      </>
    }

    <h2>サンプルコード</h2>
    <p>以下のような cURL コマンドを実行すると、API の実際の動作をお試しいただけます。</p>
    {
      window.PROP_ID_API_STAGE === 'dev' && <p>注意: 開発環境と本番環境とでは発行される ID は異なります。</p>
    }

    <h3>不動産オープン ID 取得 API</h3>
    <CodeSample code={generateIdIssueCodeSample(apiKey?.apiKey)} />

    <h3>不動産オープン ID 参照 API</h3>
    <CodeSample code={generateIdQueryCodeSample(apiKey?.apiKey)} />

    <p>API の詳細な仕様については<a href="https://geolonia.github.io/prop-id-api/api-specs.html">API 仕様書</a>をご覧ください。</p>
  </>;
};

export default ApiKeys;
