import { useAuth0 } from '@auth0/auth0-react';

import './index.scss';
import useAuth0Connector from './hooks/useAuth0Connector';
import AuthenticatedApp from './AuthenticatedApp';
import { useAppSelector } from './redux/hooks';
import LoadingSpinner from './components/LoadingSpinner';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { useState } from 'react';
import { initialToastState, ToastContext, ToastState } from './contexts/toast';
import Toasts from './components/Toasts';

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button onClick={() => loginWithRedirect()}>
      ログインする
    </button>
  );
};

const App: React.FC = () => {
  const {
    isLoading,
    isAuthenticated,
  } = useAuth0();

  useAuth0Connector();

  const authState = useAppSelector((state) => state.auth);

  if ((isLoading) || (!isLoading && isAuthenticated && !authState.apiToken)) {
    return (
      <LoadingSpinner />
    );
  }

  if (!isLoading && !isAuthenticated) {
    return <LoginButton />;
  }

  return process.env.PUBLIC_URL.startsWith('https://geolonia.github.io') ? (
    <HashRouter>
      <AuthenticatedApp />
    </HashRouter>
  ) : (
    <BrowserRouter>
      <AuthenticatedApp />
    </BrowserRouter>
  );
};

const ToastContainer = () => {
  const [toastState, setToastState] = useState<ToastState>(initialToastState);
  return <ToastContext.Provider value={
    {
      open: toastState.open,
      message: toastState.message,
      title: toastState.title,
      type: toastState.type,
      show: (title, message, type, msec) => {
        setToastState({ ...toastState, open: true, title, message, type });
        if (typeof msec === 'number') {
          setTimeout(() => setToastState({ ...toastState, open: false }), msec);
        }
      },
      close: () => { setToastState({...toastState, open: false}); },
    }
  }>
    <App />
    <Toasts />
  </ToastContext.Provider>;


};

export default ToastContainer;
