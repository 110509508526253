import React, { useCallback, useContext } from 'react';
import './Toasts.scss';
import classnames from 'classnames';
import { ToastContext } from '../contexts/toast';

const Toasts: React.FC<{}> = () => {
  const { open, title, message, close } = useContext(ToastContext);

  const closeHandler = useCallback(() => close(), [close]);

  return <div className="toast-container">
    <div className={ classnames(
      'toast',
      open ? 'show' : undefined,
      'bg-warning',
    ) } role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <strong className="me-auto">{ title }</strong>
        <button type="button" className="btn-close" aria-label="Close" onClick={closeHandler}></button>
      </div>
      <div className="toast-body">{message}</div>
    </div>
  </div>;
};

export default Toasts;
