import { createContext } from 'react';

export type ToastState = {
  open: boolean;
  title: string;
  message: string;
  type: 'success' | 'failure'
}

export const initialToastState: ToastState = {
  open: false,
  title: '',
  message: '',
  type: 'success',
};

export const ToastContext = createContext({
  ...initialToastState,
  show: (title: string, message: string, type: 'success' | 'failure', msec?: number) => {},
  close: (msec?: number) => {},
});
